.home {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.home > main {
    background-color: #151320;
    flex-basis: 100%;
}
