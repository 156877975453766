.container{
    display: flex;
    justify-content: center;
}
.portfolio{
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill,minmax(0,300px));;
    justify-content:center;
    max-width: 800px; 
    margin: 50px 16px;
    animation: fadeIn 1s;
}

@keyframes fadeIn{
    0% {
   opacity: 0;
    }

    100% {
   opacity: 1;
    }
}