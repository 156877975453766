.nav{
     background-color: #22212C;
     color: #f2f2f2;
     width: 100%;
    }

.nav__content {
    margin: auto;
   max-width: 85%;
     height: 55px;
    display: flex;
    font-size: 20px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.nav a {
    margin: 1em;
    cursor: pointer;
}
 a {
    text-decoration: none;
    color: #f2f2f2;
}

.nav p:hover{
    color: #ff80bf;
}

.nav__menu {
    display: none;

}

.nav__content__text{
    display: flex;
    align-items: center;
}

@media (max-width:500px) {
    .nav__content__text {
        display: none;
    }

    .nav__menu {
        display: inline;
    }

}
@media (max-width:600px){
    .nav__content{
        font-size: 19px;
    }
}