.footer {
    color: #f2f2f2;
    background-color: #22212C;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;

}
.footer__container{
    display: flex;
    justify-content: center;
    align-items: center;
      }
.footer__container > a{
    padding: 0;
    color:#9580ff;
    margin-right: 8px;
    text-decoration: none;
}
.footer__container > p{
       margin-right: 8px;
}
