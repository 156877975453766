.menu__content {
    position: fixed;
    margin-top: 7.5vh;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    background-color: #22212C;
    opacity: .95;
    transition: all .3s linear;
    color: #9580ff;
    font-size: 20px;
    font-weight: 500;
}

.MuiIconButton-root {
    color: #f2f2f2 !important;
}

.MuiIconButton-root:hover {
    color: #ff80bf !important;
}

.menu__show {
    height: 85.4vh;
}

.menu__show > a {
    cursor: pointer;
    padding: 0.5em 0.8em;
    transition: color 0.25s;
    border:1px solid #9580ff;
    border-radius: 5px;
}

a:hover {
    color: #ff80bf;
    border-color: #ff80bf;
    transition: color 0.25s;
}