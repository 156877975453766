
.card{
    max-width: 300px;
    max-height: 280px;
    display: flex;
    flex-direction: column;
}

.card__title{
    background-color:#22212C;
    width: 100%;
    text-align: center;
    color: #f2f2f2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card__image{
    width: 100%;
    max-height: 168.75px;
    box-sizing: border-box;
    border-left: 1px solid #22212C;
    border-right: 1px solid #22212C;
}

.card__nav{
    padding: 7px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color:#22212C;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.card__nav > a {
    border-radius: 5px;
    padding: 7px;
    font-size: 18px;
}

.card__nav >a:hover{
    color: #ff80bf;
    border-color: #ff80bf;
}         
